<template>
  <div class="product-specifications">
    <div class="product-specifications__container">
      <PageHeader :product="product" :prepend="'@Specifications'" />
      <Specifications :product="product" />
    </div>
    <AsideCard :product="product" :navigation-height="navigationHeight" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import PageHeader from "~/modules/product/ui/components/PageHeader.vue";
import AsideCard from "~/modules/product/ui/components/AsideCard.vue";
import Specifications from "~/modules/product/ui/components/Specifications.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

const patternSeo = {
  metaTitle: {
    postfix: () => _T("@Specifications"),
  },
  metaDescription: {
    prefix: () => _T("@Product characteristics"),
    postfix: () => _T("@online store") + " | " + _T("@Trademark"),
  },
  metaKeywords: {
    postfix: () => _T("@Specifications"),
  },
};

configureProductSeo(props.product, patternSeo);
</script>

<style lang="scss" scoped>
.product-specifications {
  @include flex-container(row, center, flex-start);
  gap: 44px;

  @include bigMobile {
    gap: 16px;
  }

  &__container {
    width: 100%;
    max-width: 750px;

    @include flex-container(column, flex-start);
    gap: 16px;
  }
}
</style>
